<template>
	<div>
    <v-snackbar v-model="alerta.status" :color="alerta.color" timeout="3000">
      {{ alerta.msg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="alerta = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogExportar" width="450px" persistent>
      <v-card>
        <v-card-title class="primary white--text pr-3">
          {{ buscado.idcategoria == 0 ? 'Contatos' : categorias.filter((v) => v.idcategoria == buscado.idcategoria )[0].categoria }}
          <v-spacer/>
          <v-btn icon @click="dialogExportar=false, qtdContatos = 0" class="mb-1">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-progress-linear indeterminate absolute :active="carregando2" />
        <v-card-subtitle class="py-1 pt-4">
          Selecione a quantidade de contatos que deseja exportar:
        </v-card-subtitle>
        <v-card-text class="pt-0">
          <v-radio-group hide-details class="mt-1" v-model="qtdContatos">
            <v-radio :value="parseInt(50)" v-if="dados.total > 50" label="50 primeiros contatos."></v-radio>
            <v-radio :value="parseInt(100)" v-if="dados.total > 100" label="100 primeiros contatos."></v-radio>
            <v-radio :value="parseInt(300)" v-if="dados.total > 300" label="300 primeiros contatos."></v-radio>
            <v-radio :value="parseInt(500)" v-if="dados.total > 500" label="500 primeiros contatos."></v-radio>
            <v-radio :value="parseInt(1000)" v-if="dados.total > 1000" label="1000 primeiros contatos."></v-radio>
            <v-radio :value="dados.total" :label="`Todos contatos (${dados.total})`"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn width="100%" class="primary" @click="exportar()" :loading="carregando2" :disabled="qtdContatos == 0">
            <v-icon class="mr-2" size="18">mdi-file-delimited-outline</v-icon> Exportar .CSV
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProduto" scrollable width="900px">
      <v-card class="primary" dark>
        <v-card-title class="justify-center">
          <span class="text-subtitle-1"> {{ clienteSelecionado }} </span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table
              light
              :headers="[
                { text: 'Código', value: 'idsubproduto', align: 'start', sortable: false  },
                { text: 'Produto', value: 'produto', align: 'start', sortable: false  },
                { text: 'Quantidade', value: 'qtdproduto', align: 'center', sortable: false  },
                { text: 'Valor', value: 'valorvendido', align: 'center', sortable: false  },
              ]"
              :items="produtos"
              :items-per-page="-1"
              hide-default-footer
              :loading="carregando2"
              loading-text="Carregando produtos..."
              no-data-text="Não há produtos disponíveis"
          >
            <template v-slot:item.valorvendido="{ item }">
              <span class="mt-1">{{  item.valorvendido | formataDinheiro }}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="px-2">
          <v-btn color="white" class="mx-auto" elevation="0" width="100%" text @click="dialogProduto = false, produtos = []">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogOrcamento" scrollable width="1000px">
      <v-card class="primary" dark>
        <v-card-title class="justify-center">
          <span class="text-subtitle-1"> {{ clienteSelecionado }} </span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table
              light
              :headers="[
                { text: 'Loja', value: 'idempresa', align: 'start', sortable: false  },
                { text: 'Vendedor', value: 'idcliente', align: 'start', sortable: false  },
                { text: 'N° Orçamento', value: 'idorcamento', align: 'center', sortable: false  },
                { text: 'Data Orçamento', value: 'dtmovimento', align: 'center', sortable: false  },
                { text: 'Valor Orçamento', value: 'valororcado', align: 'center', sortable: false  },
              ]"
              :items="orcamentos"
              :items-per-page="-1"
              hide-default-footer
              :loading="carregando2"
              loading-text="Carregando orçamentos..."
              no-data-text="Não há orçamentos disponíveis"
          >
            <template v-slot:item.idempresa="{ item }">
              {{ item.idempresa }} - {{  item.empresa }}
            </template>
            <template v-slot:item.idcliente="{ item }">
              {{  item.cliente }}
            </template>
            <!--            <template v-slot:item.dtmovimento="{ item }">-->
            <!--              {{  item.dtmovimento | formataData }}-->
            <!--            </template>-->
            <template v-slot:item.dtmovimento="{ item }">
              {{  item.dtmovimento | formataData }}
            </template>
            <template v-slot:item.valororcado="{ item }">
              {{  item.valororcado | formataDinheiro }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="px-2">
          <v-btn color="white" class="mx-auto" elevation="0" width="100%" text @click="dialogOrcamento = false, orcamentos = []">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAssociacao" scrollable width="1200px">
      <v-card class="primary" dark>
        <v-card-title class="justify-center">
          <span class="text-subtitle-1"> {{ clienteSelecionado }} </span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table
              light
              sort-by="confianca"
              sort-desc
              :headers="[
                { text: 'Item Comprado', value: 'idprodorigem', align: 'start', sortable: true  },
                // { text: 'Título', value: 'prodorigem', align: 'center', sortable: false  },
                { text: 'Item Associado Sugerido', value: 'idprodresult', align: 'start', sortable: true  },
                // { text: 'Vencimento', value: 'prodresult', align: 'center', sortable: false  },
                { text: 'Confiança', value: 'confianca', align: 'center', sortable: true  },
                { text: 'Frequência', value: 'frequencia', align: 'center', sortable: true  },
                // { text: 'Elevação', value: 'elevacao', align: 'center', sortable: true  },
              ]"
              :items="produtosAssociacao"
              :items-per-page="-1"
              hide-default-footer
              :loading="carregando2"
              loading-text="Carregando produtos..."
              no-data-text="Não há produtos disponíveis"
          >
            <template v-slot:item.idprodorigem="{ item }">
              {{ item.idprodorigem }} - {{  item.prodorigem | formataTextoProdutoCd }}
            </template>
            <template v-slot:item.idprodresult="{ item }">
              {{ item.idprodresult }} - {{  item.prodresult | formataTextoProdutoCd }}
            </template>
            <template v-slot:item.confianca="{ item }">
              <v-chip small>
                {{ (item.confianca * 100).toFixed(2) }}%
              </v-chip>
            </template>
            <template v-slot:item.frequencia="{ item }">
              <v-chip small>
                {{ item.frequencia.toFixed(5) }}
              </v-chip>
            </template>
            <template v-slot:item.elevacao="{ item }">
              <v-chip small>
                {{ item.elevacao.toFixed(0) }}
              </v-chip>
            </template>
          </v-data-table>
<!--          <v-list dense>-->
<!--            <v-list-item-->
<!--                v-for="(result, index) in produtosAssociacao"-->
<!--                :key="index"-->
<!--                class="result-item"-->
<!--            >-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title class="text-uppercase font-weight-bold">-->
<!--                  <v-icon class="mr-2" color="primary">mdi-arrow-right-circle</v-icon>-->
<!--                  Antecedent:-->
<!--                  <span class="text-primary">{{ result.idprodorigem }} - {{ result.prodorigem }}</span>-->
<!--                </v-list-item-title>-->
<!--                <v-list-item-subtitle>-->
<!--                  <v-icon class="mr-2" color="secondary">mdi-arrow-right</v-icon>-->
<!--                  Consequent:-->
<!--                  <span class="text-secondary">{{ result.idprodresult }} - {{ result.prodresult }}</span>-->
<!--                </v-list-item-subtitle>-->
<!--                <v-list-item-subtitle>-->
<!--                  <v-icon class="mr-2" color="success">mdi-graph</v-icon>-->
<!--                  Support:-->
<!--                  <span class="text-success">{{ result.frequencia.toFixed(4) }}</span>-->
<!--                </v-list-item-subtitle>-->
<!--                <v-list-item-subtitle>-->
<!--                  <v-icon class="mr-2" color="info">mdi-check-circle</v-icon>-->
<!--                  Confidence:-->
<!--                  <span class="text-info">{{ result.confianca.toFixed(4) }}</span>-->
<!--                </v-list-item-subtitle>-->
<!--                <v-list-item-subtitle>-->
<!--                  <v-icon class="mr-2" color="warning">mdi-alert</v-icon>-->
<!--                  Lift:-->
<!--                  <span class="text-warning">{{ result.elevacao.toFixed(4) }}</span>-->
<!--                </v-list-item-subtitle>-->
<!--              </v-list-item-content>-->
<!--            </v-list-item>-->
<!--            <v-divider-->
<!--                v-if="index < produtosAssociacao.length - 1"-->
<!--                :key="'divider-' + index"-->
<!--            ></v-divider>-->
<!--          </v-list>-->
<!--          <v-list dense light>-->
<!--            <v-list-item-->
<!--                v-for="(result, index) in produtosAssociacao"-->
<!--                :key="index"-->
<!--            >-->
<!--              <v-row align="center">-->
<!--                &lt;!&ndash; Antecedents Section &ndash;&gt;-->
<!--                <v-col cols="6" class="text-center">-->
<!--                  <v-avatar size="64" class="mx-auto  my-4 mt-5">-->
<!--                    <img :src="getProductImage(result.idprodorigem)" alt="Produto Origem" />-->
<!--                  </v-avatar>-->
<!--                  <v-list-item-title class="text-uppercase font-weight-bold">-->
<!--&lt;!&ndash;                    <v-icon class="mr-1" color="primary">mdi-arrow-right-circle</v-icon>&ndash;&gt;-->
<!--                    <span color="primary">{{ result.idprodorigem }} - {{ result.prodorigem }}</span>-->
<!--                  </v-list-item-title>-->
<!--                </v-col>-->

<!--                &lt;!&ndash; Consequents Section &ndash;&gt;-->
<!--                <v-col cols="6" class="text-center">-->
<!--                  <v-avatar size="64" class="mx-auto my-4 mt-5">-->
<!--                    <img :src="getProductImage(result.idprodresult)" alt="Produto Resultante" />-->
<!--                  </v-avatar>-->
<!--                  <v-list-item-title class="text-uppercase font-weight-bold">-->
<!--&lt;!&ndash;                    <v-icon class="mr-1" color="secondary">mdi-arrow-right</v-icon>&ndash;&gt;-->
<!--                    <span color="primary">{{ result.idprodresult }} - {{ result.prodresult }}</span>-->
<!--                  </v-list-item-title>-->
<!--                </v-col>-->

<!--                &lt;!&ndash; Support, Confidence, and Lift &ndash;&gt;-->
<!--                <v-col cols="4" style="border-top: 1px solid black; border-bottom: 1px solid black">-->
<!--                  <v-list-item-subtitle class="text-center">-->
<!--                    <v-icon class="mr-1" color="success">mdi-graph</v-icon>-->
<!--                    Support:-->
<!--                    <span class="text-success">{{ result.frequencia.toFixed(4) }}</span>-->
<!--                  </v-list-item-subtitle>-->
<!--                </v-col>-->

<!--                <v-col cols="4" style="border-top: 1px solid black; border-bottom: 1px solid black">-->
<!--                  <v-list-item-subtitle class="text-center">-->
<!--                    <v-icon class="mr-1" color="info">mdi-check-circle</v-icon>-->
<!--                    Confidence:-->
<!--                    <span class="text-info">{{ result.confianca.toFixed(4) }}</span>-->
<!--                  </v-list-item-subtitle>-->
<!--                </v-col>-->

<!--                <v-col cols="4" style="border-top: 1px solid black; border-bottom: 1px solid black">-->
<!--                  <v-list-item-subtitle class="text-center">-->
<!--                    <v-icon class="mr-1" color="warning">mdi-alert</v-icon>-->
<!--                    Lift:-->
<!--                    <span class="text-warning">{{ result.elevacao.toFixed(4) }}</span>-->
<!--                  </v-list-item-subtitle>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-list-item>-->
<!--            <v-divider></v-divider>-->
<!--          </v-list>-->
        </v-card-text>
        <v-card-actions class="px-2">
          <v-btn color="white" class="mx-auto" elevation="0" width="100%" text @click="dialogAssociacao = false, produtosAssociacao = []">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTitulo" scrollable width="900px">
      <v-card class="primary" dark>
        <v-card-title class="justify-center">
          <span class="text-subtitle-1"> {{ clienteSelecionado }} </span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table
              light
              :headers="[
                { text: 'Loja', value: 'idempresa', align: 'start', sortable: false  },
                { text: 'Título', value: 'idtitulo', align: 'center', sortable: false  },
                { text: 'Dias Atraso', value: 'diasatraso', align: 'center', sortable: false  },
                { text: 'Vencimento', value: 'dtvencimento', align: 'center', sortable: false  },
                { text: 'Saldo', value: 'valorsaldo', align: 'center', sortable: false  },
              ]"
              :items="titulos"
              :items-per-page="-1"
              hide-default-footer
              :loading="carregando2"
              loading-text="Carregando produtos..."
              no-data-text="Não há produtos disponíveis"
          >
            <template v-slot:item.idempresa="{ item }">
              {{ item.idempresa }} - {{  item.empresa }}
            </template>
            <template v-slot:item.idtitulo="{ item }">
              {{ item.idtitulo }} - {{  item.digitotitulo }}
            </template>
<!--            <template v-slot:item.dtmovimento="{ item }">-->
<!--              {{  item.dtmovimento | formataData }}-->
<!--            </template>-->
            <template v-slot:item.dtvencimento="{ item }">
              {{  item.dtvencimento | formataData }}
            </template>
            <template v-slot:item.valorsaldo="{ item }">
              {{  item.valorsaldo | formataDinheiro }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="px-2">
          <v-btn color="white" class="mx-auto" elevation="0" width="100%" text @click="dialogTitulo = false, titulos = []">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title class="px-2 py-0">
        <v-col cols="12" class="px-1 py-3">
          <v-select
              outlined
              dense
              hide-details
              :items="categorias"
              item-text="categoria"
              item-value="idcategoria"
              label="Classificação"
              v-model="busca.idcategoria"
              @change="limparGeral()"
          ></v-select>
        </v-col>

        <v-col cols="12" class="px-1 pt-0" v-if="busca.idcategoria != 0 && busca.idcategoria != 5">
          <v-divider/>
        </v-col>
        <v-col cols="6" class="pa-1 pt-2" v-if="busca.idcategoria != 0 && busca.idcategoria == 3">
          <v-select
              :items="filiaisFiltro"
              item-text="filial"
              item-value="idfilial"
              label="Filial"
              outlined
              dense
              v-model="busca.idfiliais"
              hide-details
              multiple
              class="single-line-selection"
              @change="listarSecoes()"
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0">{{ item.filial }}</span>
              <span v-if="index === 1" class="grey--text text-caption">
                                (+{{ busca.idfiliais.length - 1 }})
                              </span>
            </template>

            <template #prepend-item>
              <v-list-item  class="pr-0" dense>
                <v-list-item-action>
                  <v-checkbox v-model="selectAllChecked" @click.stop="toggleSelectAll('CHECKBOX')"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content @click="toggleSelectAll('LABEL')">
                  <!-- Use uma tag label para vincular ao checkbox -->
                  <label class="text-subtitle-2">Todas</label>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col cols="6" class="pa-1 pt-2" v-if="busca.idcategoria != 0 && busca.idcategoria == 3">
          <v-select
              :items="semanas"
              item-text="descricao"
              item-value="numsemana"
              label="Semana"
              outlined
              dense
              v-model="busca.semana"
              hide-details
              @change="listarSecoes()"
          >
          </v-select>
        </v-col>
        <v-col cols="4" class="pa-1 pt-2" v-if="busca.idcategoria != 0 && busca.idcategoria != 3 && busca.idcategoria != 5">
          <v-select
              :items="filiaisFiltro"
              item-text="filial"
              item-value="idfilial"
              label="Filial"
              outlined
              dense
              v-model="busca.idfiliais"
              hide-details
              multiple
              class="single-line-selection"
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0">{{ item.filial }}</span>
              <span v-if="index === 1" class="grey--text text-caption">
                                (+{{ busca.idfiliais.length - 1 }})
                              </span>
            </template>

            <template #prepend-item>
              <v-list-item  class="pr-0" dense>
                <v-list-item-action>
                  <v-checkbox v-model="selectAllChecked" @click.stop="toggleSelectAll('CHECKBOX')"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content @click="toggleSelectAll('LABEL')">
                  <!-- Use uma tag label para vincular ao checkbox -->
                  <label class="text-subtitle-2">Todas</label>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col cols="4" class="pa-1 pt-2" v-if="busca.idcategoria != 0 && busca.idcategoria != 3 && busca.idcategoria != 5">
          <InputDatePicker :outlined="true" :dense="true" v-model="busca.dtinicio" label="Período inicial" />
        </v-col>
        <v-col cols="4" class="pa-1 pt-2"  v-if="busca.idcategoria != 0 && busca.idcategoria != 3 && busca.idcategoria != 5">
          <InputDatePicker :outlined="true" :dense="true" v-model="busca.dtfim" label="Período final" />
        </v-col>

        <v-col cols="4" class="pa-1 pt-3" v-if="busca.idcategoria == 3 && !!busca.idfiliais && !!busca.semana">
          <v-select
              :items="secoes"
              item-text="descricao"
              item-value="idsecao"
              label="Categoria"
              outlined
              dense
              v-model="busca.idsecao"
              hide-details
              class="single-line-selection"
              @change="listarGrupos()"
              clearable
          >
          </v-select>
        </v-col>
        <v-col cols="4" class="pa-1 pt-3" v-if="busca.idcategoria == 3 && !!busca.idfiliais && !!busca.semana">
          <v-select
              :disabled="!busca.idsecao"
              :items="grupos"
              item-text="descricao"
              item-value="idgrupo"
              label="Grupo"
              outlined
              dense
              v-model="busca.idgrupo"
              hide-details
              class="single-line-selection"
              @change="listarSubGrupos()"
              clearable
          >
          </v-select>
        </v-col>
        <v-col cols="4" class="pa-1 pt-3" v-if="busca.idcategoria == 3 && !!busca.idfiliais && !!busca.semana">
          <v-select
              :disabled="!busca.idgrupo"
              :items="subgrupos"
              item-text="descricao"
              item-value="idsubgrupo"
              label="Sub Grupo"
              outlined
              dense
              v-model="busca.idsubgrupo"
              hide-details
              class="single-line-selection"
              @change="listarSubProdutos()"
              clearable
          >
          </v-select>
        </v-col>
        <v-col cols="12" class="pa-1 pt-3" v-if="busca.idcategoria == 3 && !!busca.idsecao && !!busca.idgrupo && !!busca.idsubgrupo ">
          <v-select
                :items="subprodutos"
                item-text="descricao"
                item-value="idproduto"
                label="Produto"
                outlined
                dense
                v-model="busca.idproduto"
                hide-details
                class="single-line-selection"
                clearable
            >
            </v-select>
        </v-col>
        <v-col cols="12" class="px-1 pb-0" v-if="busca.idcategoria != 0 && busca.idcategoria != 5">
          <v-divider/>
        </v-col>
        <v-col cols="12" class="px-1" v-if="busca.idcategoria != 0">
          <v-btn outlined :disabled="carregando || (busca.idcategoria == 3 && !busca.semana)" color="primary" class="elevation-0" @click="listar(0)" width="100%">
                Buscar Contatos
                <v-icon size="20" class="ml-1">mdi-magnify</v-icon>
              </v-btn>
        </v-col>
      </v-card-title>
    </v-card>
    <v-card class="mt-5 primary" dark>
      <v-card-title class="py-3 px-3">
        {{ buscado.idcategoria == 0 ? 'Contatos' : categorias.filter((v) => v.idcategoria == buscado.idcategoria )[0].categoria }}
        <v-spacer/>
        <v-menu
            v-model="menu"
            v-if="busca.idcategoria == 3 && busca.idcategoria != 0"
            transition="slide-y-transition"
            :close-on-content-click="false"
            left
            bottom
            offset-y
        >
          <template v-slot:activator="{ on, attrs  }">
            <v-badge v-bind="attrs" color="green" overlap :content="(buscado.rangeConfianca[0] != minConfianca || buscado.rangeConfianca[1] != maxConfianca) ? 1 : 0" :value="(buscado.rangeConfianca[0] != minConfianca || buscado.rangeConfianca[1] != maxConfianca) ? 1 : 0" offset-x="27">
              <v-btn
                  :disabled="carregando || ( dados.total == 0 && !(buscado.rangeConfianca[0] != minConfianca || buscado.rangeConfianca[1] != maxConfianca))"
                  class="mr-3"
                  outlined
                  text
                  v-on="on"
                  elevation="0">
                <v-icon class="mr-1">mdi-filter-menu-outline</v-icon>
                Filtrar
              </v-btn>
            </v-badge>
          </template>
          <v-card style="border: rgba(0,0,0,0.15) 1px solid !important;" class="primary" elevation="6" dark width="600px">
            <v-card-title>
              Métricas de Associação por Produto
            </v-card-title>
            <v-card-subtitle>
              Cálculo baseado nas vendas do período de 01/01/2024 à 31/07/2024.
              <v-btn class="float-end mt-n6" icon @click="menu=false">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-card-subtitle>
            <v-divider/>
            <v-card-text class="white py-0">
              <v-list light>
                <v-list-item-content>
                  <v-list-item-title class="text-center">Confiança</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-range-slider
                        light
                        v-model="busca.rangeConfianca"
                        :max="maxConfianca"
                        :min="minConfianca"
                        hide-details
                        class="align-center py-3"
                    >
                      <template v-slot:prepend>
                        <v-text-field
                            label="Min."
                            class="mt-n4"
                            :value="busca.rangeConfianca[0]"
                            hide-details
                            type="number"
                            style="width: 60px"
                            @change="$set(busca.rangeConfianca, 0, $event)"
                        ></v-text-field>
                      </template>
                      <template v-slot:append>
                        <v-text-field
                            label="Max."
                            class="mt-n4"
                            :value="busca.rangeConfianca[1]"
                            hide-details
                            type="float"
                            style="width: 60px"
                            @change="$set(busca.rangeConfianca, 1, $event)"
                        ></v-text-field>
                      </template>
                    </v-range-slider></v-list-item-subtitle>
                </v-list-item-content>
<!--                <v-divider/>-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title class="text-center">Frêquencia</v-list-item-title>-->
<!--                  <v-list-item-subtitle>-->
<!--                    <v-range-slider-->
<!--                        disabled-->
<!--                        light-->
<!--                        v-model="busca.rangeFrequencia"-->
<!--                        :max="maxFrequencia"-->
<!--                        :min="minFrequencia"-->
<!--                        hide-details-->
<!--                        class="align-center py-3"-->
<!--                  >-->
<!--                    <template v-slot:prepend>-->
<!--                      <v-text-field-->
<!--                          disabled-->
<!--                          label="Min."-->
<!--                          class="mt-n4"-->
<!--                          :value="busca.rangeFrequencia[0]"-->
<!--                          hide-details-->
<!--                          type="number"-->
<!--                          style="width: 60px"-->
<!--                          @change="$set(busca.rangeFrequencia, 0, $event)"-->
<!--                      ></v-text-field>-->
<!--                    </template>-->
<!--                    <template v-slot:append>-->
<!--                      <v-text-field-->
<!--                          disabled-->
<!--                          label="Max."-->
<!--                          class="mt-n4"-->
<!--                          :value="busca.rangeFrequencia[1]"-->
<!--                          hide-details-->
<!--                          type="number"-->
<!--                          style="width: 60px"-->
<!--                          @change="$set(busca.rangeFrequencia, 1, $event)"-->
<!--                      ></v-text-field>-->
<!--                    </template>-->
<!--                  </v-range-slider></v-list-item-subtitle>-->
<!--                </v-list-item-content>-->
<!--                <v-divider/>-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title class="text-center">Relevância</v-list-item-title>-->
<!--                  <v-list-item-subtitle>-->
<!--                    <v-range-slider-->
<!--                        disabled-->
<!--                        light-->
<!--                        v-model="busca.rangeRelevancia"-->
<!--                        :max="maxRelevancia"-->
<!--                        :min="minRelevancia"-->
<!--                        hide-details-->
<!--                        class="align-center py-3"-->
<!--                  >-->
<!--                    <template v-slot:prepend>-->
<!--                      <v-text-field-->
<!--                          label="Min."-->
<!--                          class="mt-n4"-->
<!--                          :value="busca.rangeRelevancia[0]"-->
<!--                          hide-details-->
<!--                          type="number"-->
<!--                          style="width: 60px"-->
<!--                          @change="$set(busca.rangeRelevancia, 0, $event)"-->
<!--                      ></v-text-field>-->
<!--                    </template>-->
<!--                    <template v-slot:append>-->
<!--                      <v-text-field-->
<!--                          label="Max."-->
<!--                          class="mt-n4"-->
<!--                          :value="busca.rangeRelevancia[1]"-->
<!--                          hide-details-->
<!--                          type="number"-->
<!--                          style="width: 60px"-->
<!--                          @change="$set(busca.rangeRelevancia, 1, $event)"-->
<!--                      ></v-text-field>-->
<!--                    </template>-->
<!--                  </v-range-slider></v-list-item-subtitle>-->
<!--                </v-list-item-content>-->
              </v-list>
            </v-card-text>
            <v-divider/>
            <v-card-actions class="white">
              <v-col class="pa-1">
                <v-btn outlined color="primary" width="100%" @click="menu = false; limparRegras()">
                  Restaurar
                </v-btn>
              </v-col>
              <v-col class="pa-1">
                <v-btn class="success elevation-0" width="100%" @click="menu = false; listar(0)">
                  Aplicar Filtro
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-btn :disabled="carregando || dados.total == 0" class="success" elevation="1" @click="dialogExportar=true,qtdContatos = 0">
          <v-icon class="mr-2">mdi-contacts</v-icon>Exportar
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-3 pt-0">
        <v-data-table
            class="custom-data-table"
            fixed-header
            light
            :headers="montaHeader()"
            :items="dados.lista"
            :loading="carregando"
            loading-text="Carregando contatos..."
            no-data-text="Não há contatos disponíveis"
            hide-default-footer
            :items-per-page="-1"
        >
<!--          <template v-slot:item.empresa="{ item }">-->
<!--            {{ item.idempresa }} - {{  item.empresa }}-->
<!--          </template>-->

<!--          <template v-slot:item.cliente="{ item }">-->
<!--            {{ item.idcliente }} - {{ item.cliente }}-->
<!--          </template>-->

          <template v-slot:item.diasatraso="{ item }">
            <td class="pr-md-16 text-center">
<!--              <v-chip small class="primary">-->
                <span class="font-weight-medium">{{item.diasatraso}} dias</span>
<!--              </v-chip>-->
            </td>
          </template>

          <template v-slot:item.valorvendido="{ item }">
            <div class="d-flex">
              <v-btn
                  :disabled="carregando"
                  icon
                  small
                  @click="listarProdutos(item.idcliente), clienteSelecionado = `${item.idcliente} - ${item.cliente}`"
                  class="mr-2 primary"
                  color="white"
              >
                <v-icon size="18">mdi-magnify-plus-outline</v-icon>
              </v-btn>
              <span class="font-weight-medium mt-1">{{  item.valorvendido | formataDinheiro }}</span>
            </div>
          </template>

          <template v-slot:item.valororcado="{ item }">
            <div class="d-flex">
              <v-btn
                  :disabled="carregando"
                  icon
                  small
                  @click="listarOrcamento(item.idcliente), clienteSelecionado = `${item.idcliente} - ${item.cliente}`"
                  class="mr-2 primary"
                  color="white"
              >
                <v-icon size="18">mdi-magnify-plus-outline</v-icon>
              </v-btn>
              <span class="font-weight-medium mt-1">{{  item.valororcado | formataDinheiro }}</span>
            </div>
          </template>

          <template v-slot:item.valorsaldo="{ item }">
            <div class="d-flex">
              <v-btn
                  :disabled="carregando"
                  icon
                  small
                  @click="listarTitulos(item.idcliente), clienteSelecionado = `${item.idcliente} - ${item.cliente}`"
                  class="mr-2 primary"
                  color="white"
              >
                <v-icon size="18">mdi-magnify-plus-outline</v-icon>
              </v-btn>
              <span class="font-weight-medium mt-1">{{  item.valorsaldo | formataDinheiro }}</span>
            </div>
          </template>

          <template v-slot:item.valorconfianca="{ item }">
            <div class="d-flex ml-4">
              <v-btn
                  :disabled="carregando"
                  icon
                  small
                  @click="listarProdutosContatoSemana(item.idcliente), clienteSelecionado = `${item.idcliente} - ${item.cliente}`"
                  class="mr-2 primary"
                  color="white"
              >
                <v-icon size="18">mdi-magnify-plus-outline</v-icon>
              </v-btn>
              <span class="font-weight-medium mt-1">{{  (item.valorconfianca*100).toFixed(2) }}</span>
            </div>
          </template>

          <template v-slot:item.contatocelular="{ item }">
            <div class="d-flex align-center justify-center">
              <v-btn
                  :disabled="carregando"
                  icon
                  small
                  @click="copyToClipboard(item.contatocelular)"
                  class="mr-2 primary"
                  color="white"
              >
                <v-icon size="15">mdi-content-copy</v-icon>
              </v-btn>
              <span class="phone-number text-truncate">{{ formatPhoneNumber(item.contatocelular) }}</span>
            </div>
          </template>
          <template v-slot:footer>
              <v-divider/>
              <v-progress-linear v-if="dados.total > 0" indeterminate absolute :active="carregando" />
              <Paginacao
                  class="py-2"
                  v-if="dados.total > pgLimitInt"
                  :total="dados.total"
                  @atualizar="listar"
                  :carregando="carregando"
                  :pg="pgSelecionada"
                  :pgLimitInt="pgLimitInt"
              />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
	</div>
</template>

<script>
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";
import Paginacao from "@/Components/Widgets/Paginacao.vue";
import axios from "axios";

export default {
	name: "VendaPedidoLista",
	mixins: [mixinFilial],
	components: { Paginacao,  InputDatePicker },
	data: () => ({
    minFrequencia: 0,
    maxFrequencia: 100,

    minConfianca: 0,
    maxConfianca: 100,

    minRelevancia: 0,
    maxRelevancia: 100,

    menu: false,

    dialogProduto: false,
    dialogOrcamento: false,
    dialogTitulo: false,
    dialogAssociacao: false,
    dialogExportar: false,

    dados: { lista: [], total: 0},
    categorias: [],
    semanas: [],
    produtos: [],
    produtosAssociacao: [],
    orcamentos: [],
    titulos: [],
    clienteSelecionado: '',

    categoriaSelecionada: null,
    opcoes: [],
    opcaoSelecionada: null,

    pgSelecionada: 0,
    pgLimitInt: 50,
    qtdContatos: 0,

    alerta: { status:false, color: '', msg: ''},

		sheet: false,
		keydown: false,
    carregando: false,
    carregando2: false,

    secoes: [],
    grupos: [],
    subgrupos: [],
    subprodutos: [],

    selectAllChecked: false,
		busca: {
			dtinicio: `${new Date(new Date())
          .toLocaleDateString("pt-BR")
          .split("/")
          .reverse()
          .join("-")}`,
      dtfim: `${new Date(new Date())
          .toLocaleDateString("pt-BR")
          .split("/")
          .reverse()
          .join("-")}`,
      idcategoria: 0,
      idfiliais: [],

      semana: null,
      idsecao: 0,
      idgrupo: 0,
      idsubgrupo: 0,
      idproduto: 0,

      rangeFrequencia: [0, 100],
      rangeConfianca: [0, 100.000],
      rangeRelevancia: [0, 100],
    },
    buscado: {
      dtinicio: '',
      dtfim: '',
      idcategoria: 0,
      idfiliais: [],

      semana: null,
      idsecao: 0,
      idgrupo: 0,
      idsubgrupo: 0,
      idproduto: 0,

      rangeFrequencia: [0, 100],
      rangeConfianca: [0, 100],
      rangeRelevancia: [0, 100],
    }
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 13 &&
						v.idfilial != 22 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 32 &&
						v.idfilial != 33 &&
						v.idfilial != 34 &&
						v.idfilial != 37 &&
						v.idfilial != 40
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
    listarSecoes() {
      this.busca.idsecao = null;
      this.busca.idgrupo = null;
      this.busca.idsubgrupo = null;
      this.busca.idproduto = null;
      this.secoes = [];
      this.grupos = [];
      this.subgrupos = [];
      this.subprodutos = [];
      const index = this.semanas.findIndex(item => item.numsemana === this.busca.semana);
      if (index !== -1) {
        return axios
            .post(`${this.backendUrl}contatos/produto/secao/listar`, {
              idfilial: this.busca.idfiliais.join(',') || null,
              dtini: this.semanas[index].dtini,
              dtfim: this.semanas[index].dtfim,
            })
            .then((res) => {
              this.secoes = res.data.lista;
            });
      } else {
        console.error("Número de semana não encontrado.");
        this.carregando = false;
        this.carregando2 = false;
        // Opcional: Trate o erro adequadamente, como exibir uma mensagem ao usuário
        return Promise.reject("Número de semana não encontrado.");
      }
    },
    listarGrupos() {
      this.busca.idgrupo = null;
      this.busca.idsubgrupo = null;
      this.busca.idproduto = null;
      this.grupos = [];
      this.subgrupos = [];
      this.subprodutos = [];
      const index = this.semanas.findIndex(item => item.numsemana === this.busca.semana);
      if (index !== -1) {
        return axios
            .post(`${this.backendUrl}contatos/produto/grupo/listar`, {
              idfilial: this.busca.idfiliais.join(',') || null,
              idsecao: this.busca.idsecao || null,
              dtini: this.semanas[index].dtini || null,
              dtfim: this.semanas[index].dtfim || null,
            })
            .then((res) => {
              this.grupos = res.data.lista;
            })
      } else {
        console.error("Número de semana não encontrado.");
        this.carregando = false;
        this.carregando2 = false;
        // Opcional: Trate o erro adequadamente, como exibir uma mensagem ao usuário
        return Promise.reject("Número de semana não encontrado.");
      }
    },
    listarSubGrupos() {
      this.busca.idproduto = null;
      this.busca.idsubgrupo = null;
      this.subgrupos = [];
      this.subprodutos = [];
      const index = this.semanas.findIndex(item => item.numsemana === this.busca.semana);
      if (index !== -1) {
      return axios
          .post(`${this.backendUrl}contatos/produto/subgrupo/listar`, {
            idfilial: this.busca.idfiliais.join(',') || null,
            idsecao: this.busca.idsecao || null,
            idgrupo: this.busca.idgrupo || null,
            dtini: this.semanas[index].dtini || null,
            dtfim: this.semanas[index].dtfim || null,
          })
          .then((res) => {
            this.subgrupos = res.data.lista;
          })
      } else {
        console.error("Número de semana não encontrado.");
        this.carregando = false;
        this.carregando2 = false;
        // Opcional: Trate o erro adequadamente, como exibir uma mensagem ao usuário
        return Promise.reject("Número de semana não encontrado.");
      }
    },
    listarSubProdutos() {
      this.busca.idproduto = null;
      this.subprodutos = [];
      const index = this.semanas.findIndex(item => item.numsemana === this.busca.semana);
      if (index !== -1) {
        return axios
            .post(`${this.backendUrl}contatos/produto/subproduto/listar`, {
              idfilial: this.busca.idfiliais.join(',') || null,
              dtini: this.semanas[index].dtini || null,
              dtfim: this.semanas[index].dtfim || null,
              idsecao: this.busca.idsecao,
              idgrupo: this.busca.idgrupo,
              idsubgrupo: this.busca.idsubgrupo,
            })
            .then((res) => {
              this.subprodutos = res.data.lista;
            })
      } else {
        console.error("Número de semana não encontrado.");
        this.carregando = false;
        this.carregando2 = false;
        // Opcional: Trate o erro adequadamente, como exibir uma mensagem ao usuário
        return Promise.reject("Número de semana não encontrado.");
      }
    },

    montaHeader(){
      let header

      switch (this.buscado.idcategoria) {
        case 1:
          header = [
            { text: 'Código', value: 'idcliente', align: 'start', sortable: false  },
            { text: 'Nome', value: 'cliente', align: 'start', sortable: false  },
            { text: 'Total Vendido', value: 'valorvendido', align: 'start', class: 'pl-10', sortable: false  },
            { text: 'Contato Celular', value: 'contatocelular', align: 'center', sortable: false  },
          ];
          break;
        case 2:
          header = [
            { text: 'Código', value: 'idcliente', align: 'start', sortable: false  },
            { text: 'Nome', value: 'cliente', align: 'start', sortable: false  },
            { text: 'Total Orçado', value: 'valororcado', align: 'start', class: 'pl-10', sortable: false  },
            { text: 'Contato Celular', value: 'contatocelular', align: 'center', sortable: false  },
          ];
          break;
        case 3:
          header = [
            { text: 'Código', value: 'idcliente', align: 'start', sortable: false  },
            { text: 'Nome', value: 'cliente', align: 'start', sortable: false  },
            { text: 'Média de Confiança', value: 'valorconfianca', align: 'start', class: 'pl-5', sortable: false  },
            { text: 'Contato Celular', value: 'contatocelular', align: 'center', sortable: false  },
          ];
          break;
        case 4:
          header = [
            { text: 'Código', value: 'idcliente', align: 'start', sortable: false  },
            { text: 'Nome', value: 'cliente', align: 'start', sortable: false  },
            { text: 'Maior Atraso', value: 'diasatraso', align: 'center', class: 'pr-md-16',sortable: false },
            { text: 'Total Saldo', value: 'valorsaldo', align: 'start', class: 'pl-10', sortable: false  },
            { text: 'Contato Celular', value: 'contatocelular', align: 'center', sortable: false  },
          ];
          break;
        default:
          header = [
            { text: 'Código', value: 'idcliente', align: 'start', sortable: false  },
            { text: 'Nome', value: 'cliente', align: 'start', sortable: false  },
            { text: 'Valor Total', value: 'valorvendido', align: 'start', class: 'pl-10', sortable: false  },
            { text: 'Contato Celular', value: 'contatocelular', align: 'center', sortable: false  },
          ];
      }

      return header

    },

    toggleSelectAll(tipo) {
      if (this.busca.idcategoria==3){
        this.listarSecoes();
      }
      if (tipo == 'CHECKBOX'){
        if (this.selectAllChecked) {
          // Se a opção "Selecionar Todos" estiver desmarcada, selecionar todas as filiais
          this.busca.idfiliais = this.filiaisFiltro.map(item => item.idfilial);
        } else {
          // Se a opção "Selecionar Todos" estiver marcada, limpar todas as seleções
          this.busca.idfiliais = [];
        }
      } else {
        if (!this.selectAllChecked) {
          // Se a opção "Selecionar Todos" estiver desmarcada, selecionar todas as filiais
          this.busca.idfiliais = this.filiaisFiltro.map(item => item.idfilial);
        } else {
          // Se a opção "Selecionar Todos" estiver marcada, limpar todas as seleções
          this.busca.idfiliais = [];
        }
        this.selectAllChecked = !this.selectAllChecked;
      }

    },

    exportar() {
      this.carregando2 = true;
      const index = this.semanas.findIndex(item => item.numsemana === this.busca.semana);
      return axios
          .post(`${this.backendUrl}contatos/qualificados/exportar`, {
            idfilial: this.busca.idfiliais.join(',') || null,
            dtini: this.busca.idcategoria == 3 ? this.semanas[index].dtini || null : this.busca.dtinicio || null,
            dtfim: this.busca.idcategoria == 3 ? this.semanas[index].dtfim || null : this.busca.dtfim || null,
            idcategoria: this.busca.idcategoria,
            limit: this.qtdContatos,
            offset: 0,
          })
          .then((res) => {
            this.carregando2 = false;
            const url = window.URL.createObjectURL(
                this.base64ToBlob(res.data)
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "arquivo de contatos.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.dialogExportar=false;
          })
          .catch(() => {
            this.carregando2 = false;
            this.dialogExportar=false;
          });
    },


    limparRegras() {
      this.busca.rangeFrequencia=[0, 100];
      this.busca.rangeConfianca=[0, 100];
      this.busca.rangeRelevancia=[0, 100];

      this.buscado.rangeFrequencia=[0, 100];
      this.buscado.rangeConfianca=[0, 100];
      this.buscado.rangeRelevancia=[0, 100];

      this.listar(0);
    },


    limparGeral() {
      this.busca.semana = null;
      this.busca.idsecao = 0;
      this.busca.idgrupo = 0;
      this.busca.idsubgrupo = 0;
      this.busca.idproduto = 0;
      this.busca.rangeFrequencia = [0, 100];
      this.busca.rangeConfianca = [0, 100];
      this.busca.rangeRelevancia = [0, 100];

      // this.buscado= {
      //   dtinicio: '',
      //   dtfim: '',
      //   idcategoria: 0,
      //   idfiliais: [],
      //
      //   semana: null,
      //   idsecao: 0,
      //   idgrupo: 0,
      //   idsubgrupo: 0,
      //   idproduto: 0,
      //
      //   rangeFrequencia: [0, 100],
      //   rangeConfianca: [0, 100],
      //   rangeRelevancia: [0, 100],
      // }
    },

    listar(pg) {
      this.pgSelecionada = pg;
      this.carregando = true;
      const index = this.semanas.findIndex(item => item.numsemana === this.busca.semana);
      return axios
          .post(`${this.backendUrl}contatos/qualificados/listar`, {
            idfilial: this.busca.idfiliais.join(',') || null,
            dtini: this.busca.idcategoria == 3 ? this.semanas[index].dtini || null : this.busca.dtinicio || null,
            dtfim: this.busca.idcategoria == 3 ? this.semanas[index].dtfim || null : this.busca.dtfim || null,
            idcategoria: this.busca.idcategoria,
            limit: this.pgLimitInt,
            offset: this.pgLimitInt * pg,
            idsecao: this.busca.idsecao || null,
            idgrupo: this.busca.idgrupo || null,
            idsubgrupo: this.busca.idsubgrupo || null,
            idproduto: this.busca.idproduto || null,
            minconfianca: this.busca.rangeConfianca[0] || 0,
            maxconfianca: this.busca.rangeConfianca[1] || 100,
          })
          .then((res) => {
            if (pg > 0) {
              this.dados.lista = res.data.lista;
            } else {
              if (res.data.lista != null) {
                this.dados = res.data;
              } else {
                this.dados = { lista: [], total: 0 }
              }
            }
            this.buscado = JSON.parse(JSON.stringify(this.busca));
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },

    listarProdutos(idclifor) {
      this.dialogProduto = true;
      this.carregando2 = true;
      return axios
          .post(`${this.backendUrl}contatos/qualificados/produtos/listar`, {
            dtini: this.buscado.dtinicio,
            dtfim: this.buscado.dtfim,
            idfilial: this.buscado.idfiliais.join(',') || null,
            idclifor: idclifor,
          })
          .then((res) => {
            if (res.data.lista != null) {
              this.produtos = res.data.lista;
            } else {
              this.produtos = [];
            }
            this.carregando2 = false;
          })
          .catch(() => {
            this.carregando2 = false;
          });
    },

    listarTitulos(idclifor) {
      this.dialogTitulo = true;
      this.carregando2 = true;
      return axios
          .post(`${this.backendUrl}contatos/qualificados/titulos/listar`, {
            dtini: this.buscado.dtinicio,
            dtfim: this.buscado.dtfim,
            idfilial: this.buscado.idfiliais.join(',') || null,
            idclifor: idclifor,
          })
          .then((res) => {
            if (res.data.lista != null) {
              this.titulos = res.data.lista;
            } else {
              this.titulos = [];
            }
            this.carregando2 = false;
          })
          .catch(() => {
            this.carregando2 = false;
          });
    },

    listarOrcamento(idclifor) {
      this.dialogOrcamento = true;
      this.carregando2 = true;
      return axios
          .post(`${this.backendUrl}contatos/qualificados/orcamentos/listar`, {
            dtini: this.buscado.dtinicio,
            dtfim: this.buscado.dtfim,
            idfilial: this.buscado.idfiliais.join(',') || null,
            idclifor: idclifor,
          })
          .then((res) => {
            if (res.data.lista != null) {
              this.orcamentos = res.data.lista;
            } else {
              this.orcamentos = [];
            }
            this.carregando2 = false;
          })
          .catch(() => {
            this.carregando2 = false;
          });
    },

    listarCategorias() {
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}categorias/contato/listar`, {
          })
          .then((res) => {
            this.categorias = res.data.lista;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },

    listarSemanas() {
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}filtro/contato/semana/listar`, {
          })
          .then((res) => {
            this.semanas = res.data.lista;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },

    listarProdutosContatoSemana(idclifor) {
      this.dialogAssociacao= true;
      this.carregando = true;
      const index = this.semanas.findIndex(item => item.numsemana === this.busca.semana);
      if (index !== -1) {
        return axios
            .post(`${this.backendUrl}produtos/contato/semana/listar`, {
              idfilial: this.busca.idfiliais.join(',') || null,
              dtini: this.busca.idcategoria == 3 ? this.semanas[index].dtini || null : this.busca.dtinicio || null,
              dtfim: this.busca.idcategoria == 3 ? this.semanas[index].dtfim || null : this.busca.dtfim || null,
              idsecao: this.busca.idsecao || null,
              idgrupo: this.busca.idgrupo || null,
              idsubgrupo: this.busca.idsubgrupo || null,
              idproduto: this.busca.idproduto || null,
              minconfianca: this.busca.rangeConfianca[0] || 0,
              maxconfianca: this.busca.rangeConfianca[1] || 100,
              idclifor: idclifor,
            })
            .then((res) => {
              this.produtosAssociacao = res.data.lista;
              this.carregando = false;
            })
            .catch(() => {
              this.carregando = false;
            })
      } else {
        console.error("Número de semana não encontrado.");
        this.carregando = false;
        this.carregando2 = false;
        // Opcional: Trate o erro adequadamente, como exibir uma mensagem ao usuário
        return Promise.reject("Número de semana não encontrado.");
      }
    },

    base64ToBlob(base64) {
      const decodedData = window.atob(base64);
      const uInt8Array = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: "text/plain" });
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
          () => {
            this.alerta.status = true;
            this.alerta.msg = 'Número copiado para a área de transferência!';
            this.alerta.color = 'green'
            // this.$toast.success('Número copiado para a área de transferência!');
          },
          () => {
            this.alerta.status = true;
            this.alerta.msg = 'Falha ao copiar número.';
            this.alerta.color = 'red'
            // this.$toast.error('Falha ao copiar número.');
          }
      );
    },

    formatPhoneNumber(phoneNumber) {
      // Formatação simples para ilustrar; ajuste conforme necessário.
      return phoneNumber.replace(/(\d{2})(\d{2})(\d{4,5})(\d{4})/, '($2) $3-$4');
    },

    getDateTwoDaysAgo() {
      const today = new Date();
      const twoDaysAgo = new Date(today);
      twoDaysAgo.setDate(today.getDate() - 2);

      const day = String(twoDaysAgo.getDate()).padStart(2, '0');
      const month = String(twoDaysAgo.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = twoDaysAgo.getFullYear();

      return `${year}-${month}-${day}`;
    },

    getFirstDayOfMonthFrom1MonthAgo() {
      const today = new Date();
      const pastDate = new Date(today);
      pastDate.setMonth(today.getMonth() - 1);

      const firstDayOfMonth = new Date(pastDate.getFullYear(), pastDate.getMonth(), 1);

      const day = String(firstDayOfMonth.getDate()).padStart(2, '0');
      const month = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = firstDayOfMonth.getFullYear();

      return `${year}-${month}-${day}`;
    },

    getFirstDayOfMonthFrom3MonthsAgo() {
      const today = new Date();
      const pastDate = new Date(today);
      pastDate.setMonth(today.getMonth() - 3);

      const firstDayOfMonth = new Date(pastDate.getFullYear(), pastDate.getMonth(), 1);

      const day = String(firstDayOfMonth.getDate()).padStart(2, '0');
      const month = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = firstDayOfMonth.getFullYear();

      return `${year}-${month}-${day}`;
    },

		async init() {
      this.listarCategorias();
      this.listarSemanas();
      this.busca.idfiliais = [this.usuario.idfilial];
		},
	},
	watch: {
    "busca.idcategoria": function () {
      switch (this.busca.idcategoria) {
        case 1:
          this.busca.dtinicio= this.getFirstDayOfMonthFrom3MonthsAgo();
          this.busca.dtfim= `${new Date(new Date())
            .toLocaleDateString("pt-BR")
            .split("/")
            .reverse()
            .join("-")}`;
          break;
        case 2:
          this.busca.dtinicio= this.getFirstDayOfMonthFrom1MonthAgo();
          this.busca.dtfim= `${new Date(new Date())
              .toLocaleDateString("pt-BR")
              .split("/")
              .reverse()
              .join("-")}`;
          break;
        case 3:
          this.busca.dtinicio= this.getFirstDayOfMonthFrom3MonthsAgo();
          this.busca.dtfim= `${new Date(new Date())
              .toLocaleDateString("pt-BR")
              .split("/")
              .reverse()
              .join("-")}`;
          break;
        case 4:
          this.busca.dtinicio= `${new Date().getFullYear()}-01-01`;
          this.busca.dtfim= this.getDateTwoDaysAgo();
          break;
        default:
          this.busca.dtinicio= this.getFirstDayOfMonthFrom3MonthsAgo();
          this.busca.dtfim= `${new Date(new Date())
              .toLocaleDateString("pt-BR")
              .split("/")
              .reverse()
              .join("-")}`;
      }
    },

		"busca.dtfim": function () {
			const dtinicio = new Date(this.busca.dtinicio).getTime();
			const dtfim = new Date(this.busca.dtfim).getTime();
			const diff = Math.abs(dtinicio - dtfim);
			const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
			const month = 365 * (1000 * 60 * 60 * 24);
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
			if (days > 365) {
				this.alerta.status = true;
				this.alerta.color = 'red';
				this.alerta.msg = 'Período entre datas não pode ser superior a 1 ano';
				this.busca.dtinicio = `${new Date(
					dtfim - month
				).getFullYear()}-${
					(new Date(dtfim - month).getMonth() + 1 < 10 ? "0" : "") +
					(new Date(dtfim - month).getMonth() + 1)
				}-${
					(new Date(dtfim - month).getDate() < 10 ? "0" : "") +
					new Date(dtfim - month).getDate()
				}`;
			}
		},
		"busca.dtinicio": function () {
			const dtinicio = new Date(this.busca.dtinicio).getTime();
			const dtfim = new Date(this.busca.dtfim).getTime();
			const diff = Math.abs(dtinicio - dtfim);
			const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
			const month = 365 * (1000 * 60 * 60 * 24);
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
			if (days > 365) {
        this.alerta.status = true;
        this.alerta.color = 'red';
        this.alerta.msg = 'Período entre datas não pode ser superior a 1 ano';
				this.busca.dtfim = `${new Date(
					dtinicio + month
				).getFullYear()}-${
					(new Date(dtinicio + month).getMonth() + 1 < 10
						? "0"
						: "") +
					(new Date(dtinicio + month).getMonth() + 1)
				}-${
					(new Date(dtinicio + month).getDate() < 10 ? "0" : "") +
					new Date(dtinicio + month).getDate()
				}`;
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
.v-avatar img {
  object-fit: cover;
}

.phone-number {
  font-weight: bold;
  color: #2c3e50;
}

::v-deep .v-data-table__wrapper td {
  height: 40px !important; /* Ajuste a altura conforme necessário */
}
</style>